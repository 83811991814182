.passed { background-color: #40bd40; color: white; }
.running { background-color: #52A4F0; color: white; }
.failed1 { background-color: #FFCCBB; }
.failed2 { background-color: #FF6C5C; color: white; }
.failed3 { background-color: #F85C4D; color: white; }
.failed4 { background-color: #E84C3D; color: white; }
.failed5 { background-color: #D83C2D; color: white;}
.unavailable { background-color: silver; }
td.endpoint { font-size: 80%; }
td.taskTitle { font-size: 90%; }
tr.testLine { cursor: pointer; }
.testDetailStatus { display: inline-block; padding: 0.2em 1em; font-weight: bold; }
pre.error {
  background: #D83C2D;
  color: white;
  font-size: 100%;
}

#page {
  margin-top: 2em;
}

.suite-status { display: inline-block; margin-right: 10px; vertical-align: top; width: 25px; height: 45px; }

.ds-copy-id { display: none; }
.ds-item-detail-row td:hover .ds-copy-id { display: inline-block; }
.ds-edit-link { display: none; }
.ds-editable-value:hover a.ds-edit-link { display: inline-block; }
.hljs { padding: 1em !important; }

.title.is-3 i { margin-right: 12px; }
.ds-menulink-ico:not(.ds-empty) { margin-right: 7px; }
.ds-menulink-ico:not(.ds-empty).ds-right { margin-right: 0; margin-left: 7px; }
.navbar-item .ds-menulink .ds-val { display: none; }
.navbar-item:hover .ds-menulink .ds-val { display: inline-block; }

.ds-detail-table tr th {
  text-align: right !important;
  width: 20%;
}
